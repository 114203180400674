const inViewPort = (el, offset) => {
  if (typeof window === 'undefined') {
    return false;
  }
  let bounds = el.getBoundingClientRect();
  let { bottom } = bounds;
  return bottom + offset > 0;
};

export default inViewPort;
