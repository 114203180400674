import React, { Component } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";

import { renderHtml } from "utils/renderHelpers";
import inViewPort from "utils/inViewPort";
import debounce from "utils/debounce";

import classNames from "classnames/bind";
import styles from "./QuickInfoPanel.module.scss";

const cx = classNames.bind(styles);

class QuickInfoPanel extends Component {
  base = null;
  state = { open: false, peek: false };

  get hero() {
    if (typeof window === `undefined`) {
      return null;
    }
    if (!this.__hero) {
      this.__hero = document.querySelector(".hero");
    }
    return this.__hero;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    document.addEventListener("mousedown", this.onDocumentMouseDown);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    document.removeEventListener("mousedown", this.onDocumentMouseDown);
  }

  onDocumentMouseDown = (e) => {
    if (this.base && !this.base.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  onScroll = debounce(() => {
    let inView = inViewPort(this.hero, -250);
    if (inView) {
      this.setState({ peek: false, open: false });
    } else if (!this.state.peek && !this.state.open) {
      this.setState({ peek: true });
    }
  }, 250);

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { items } = this.props;
    return (
      <aside
        ref={(aside) => (this.base = aside)}
        id="quick-info"
        className={cx({
          quickInfoPanel: true,
          peek: this.state.peek === true,
          open: this.state.open === true,
        })}
      >
        <button aria-controls="quick-info" onClick={this.toggle}>
          <span className="is-sr-only">Quick Access</span>
          <i className="icon">
            <FontAwesomeIcon icon={faEllipsisV} />
          </i>
        </button>
        <div>
          <ul>
            {items.map(({ heading, link, is_internal_link }, idx) => {
              return (
                <li key={`quickinfo-${idx}`}>
                  {is_internal_link === true ? (
                    <Link
                      className={styles.internalLink}
                      to={link.url}
                      title={link.label}
                    >
                      {link.label}
                    </Link>
                  ) : (
                    renderHtml(heading)
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
    );
  }
}

export default QuickInfoPanel;
