import React, { PureComponent } from "react";
import { Link } from "gatsby";
import Hero from "react-bulma-components/lib/components/hero";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import QuickInfoPanel from "./QuickInfoPanel";
import Triangles from "components/Triangles";
import BreakpointListener from "components/BreakpointListener";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import events from "utils/events";
import {
  renderText,
  renderHtml,
  PrismicLink,
  validateText,
} from "utils/renderHelpers";
import { renderFluidImage } from "utils/imageHelpers";

import classNames from "classnames/bind";
import styles from "./Hero.module.scss";
import { sine } from "utils/easing";
import posed from "react-pose";
import { SkipNavContent } from "@reach/skip-nav";
const cx = classNames.bind(styles);

const bodyConfig = {
  preEnter: {
    x: -0.1,
  },
  enter: {
    x: 1,
    beforeChildren: true,
    transition: {
      delay: 700,
    },
    staggerChildren: 100,
  },
};

const footerConfig = {
  preEnter: {
    x: -0.1,
  },
  enter: {
    x: 1,
    beforeChildren: true,
    transition: {
      duration: 1,
      delay: 800,
    },
    staggerChildren: 100,
  },
};

const bottomConfig = {
  preEnter: {
    x: `-100%`,
  },
  enter: {
    x: 0,
    beforeChildren: true,
    transition: {
      type: "tween",
      ease: sine.out,
      duration: 400,
      delay: 900,
    },
    staggerChildren: 100,
  },
};

const childConfig = {
  preEnter: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: ({ delayAmount }) => ({
      type: "tween",
      ease: sine.out,
      duration: 300,
      delay: delayAmount || 0,
    }),
  },
};

const headingAnimationConfig = {
  charPoses: {
    headingExit: {
      opacity: 0,
      y: 40,
      transition: {
        duration: 300,
      },
    },
    headingEnter: {
      opacity: 1,
      y: 0,
      transition: ({ wordIndex }) => ({
        type: "tween",
        ease: sine.out,
        duration: 500,
        delay: wordIndex * 25 + 600,
      }),
    },
  },
};

const HeadingContainer = posed.div({
  headingExit: {
    x: -1,
  },
  headingEnter: {
    beforeChildren: true,
    x: 0,
    transition: {
      duration: 1,
      delay: 700,
    },
  },
});

const AnimatedBody = posed.div(bodyConfig);
const AnimatedFooter = posed.div(footerConfig);
const AnimatedBottom = posed.div(bottomConfig);
const HeroChild = posed.div(childConfig);
const HeroH2 = posed.h5(childConfig);
const HeroH5 = posed.h5(childConfig);

const Indicator = posed.figure({
  off: {
    applyAtEnd: {
      display: `none`,
    },
    opacity: 0,
  },
  on: {
    applyAtStart: {
      display: `block`,
    },
    opacity: 1,
  },
});

class HeroFooter extends PureComponent {
  scroller = null;
  state = { isAtRight: false };

  componentDidMount() {
    this.scroller = document.getElementById("hero-footer-scroller");
  }

  onScroll = () => {
    const scrollLeft = this.scroller.scrollLeft;
    const maxScrollLeft = this.scroller.scrollWidth - this.scroller.clientWidth;

    this.setState({ isAtRight: scrollLeft > maxScrollLeft - 50 });
  };

  render() {
    const { heading, items, showBorders, showIndicator } = this.props;
    const { isAtRight } = this.state;
    const theme = this.props.theme || `black`;
    if (!items.length) return null;

    return (
      <Hero.Footer className={cx({ footer: true, [theme]: true })}>
        <AnimatedFooter>
          <Container>
            <Columns>
              <Columns.Column size={12}>
                {validateText(heading) && (
                  <HeroH2 delayAmount={300}>{heading.text}</HeroH2>
                )}
              </Columns.Column>
            </Columns>
            <Columns
              id={"hero-footer-scroller"}
              mobile
              className={styles.scroller}
              onScroll={this.onScroll}
            >
              {items.map((item, idx) => {
                let size = 3;
                const {
                  image,
                  heading,
                  subheading,
                  link,
                  anchor_id,
                  item_size,
                  is_internal_link,
                } = item;
                switch (item_size) {
                  case "one-fifth":
                  case "one fifth":
                    size = 2;
                    break;
                  case "one-quarter":
                  case "one quarter":
                  default:
                    size = 3;
                    break;
                }
                return (
                  <HeroChild
                    className={`column is-8-mobile is-5-tablet is-${size}-desktop ${cx(
                      {
                        footerItem: true,
                        hasBorder: showBorders === true,
                        [theme]: true,
                        last: idx === items.length - 1,
                      }
                    )}`}
                    key={`hero-footer-${idx}`}
                  >
                    {is_internal_link === true ? (
                      <PrismicLink
                        link={link}
                        label={link.label}
                        anchor={anchor_id || null}
                        linkState={
                          anchor_id
                            ? { scrollToAnchor: true, anchorId: anchor_id }
                            : null
                        }
                      />
                    ) : (
                      <Columns className="is-mobile" style={{ height: `100%` }}>
                        {link ? (
                          <PrismicLink
                            link={link}
                            className={styles.footerButton}
                            anchor={anchor_id || null}
                            linkState={
                              anchor_id
                                ? { scrollToAnchor: true, anchorId: anchor_id }
                                : null
                            }
                          >
                            {image && image.url && (
                              <Columns.Column
                                mobile={{ size: 3 }}
                                tablet={{ size: `one-third` }}
                              >
                                {renderFluidImage(image, null, {
                                  loading: `lazy`,
                                  maxWidth: 85,
                                })}
                              </Columns.Column>
                            )}
                            {(heading || subheading) && (
                              <Columns.Column
                                mobile={{ size: image && image.url ? 9 : 12 }}
                                tablet={{
                                  size: image && image.url ? `two-thirds` : 12,
                                }}
                                className={`is-flex`}
                              >
                                <HeroChild
                                  dangerouslySetInnerHTML={{
                                    __html: heading.html,
                                  }}
                                />
                              </Columns.Column>
                            )}
                          </PrismicLink>
                        ) : (
                          <React.Fragment>
                            {image && image.url && (
                              <Columns.Column
                                mobile={{ size: 3 }}
                                tablet={{ size: `one-third` }}
                              >
                                {renderFluidImage(image, null, {
                                  loading: `lazy`,
                                  maxWidth: 85,
                                })}
                              </Columns.Column>
                            )}
                            {(heading || subheading) && (
                              <Columns.Column
                                mobile={{ size: image && image.url ? 9 : 12 }}
                                tablet={{
                                  size: image && image.url ? `two-thirds` : 12,
                                }}
                                className={`is-flex`}
                              >
                                {renderHtml(heading, `div`)}
                              </Columns.Column>
                            )}
                          </React.Fragment>
                        )}
                      </Columns>
                    )}
                    {showBorders && <figure className={styles.border} />}
                  </HeroChild>
                );
              })}
            </Columns>
            <Indicator
              className={cx({ indicator: true, [theme]: true })}
              initialPose={showIndicator && !isAtRight ? "on" : "off"}
              pose={showIndicator && !isAtRight ? "on" : "off"}
            >
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </Indicator>
          </Container>
        </AnimatedFooter>
      </Hero.Footer>
    );
  }
}

const HeroBottomCallout = ({
  heading,
  largeHeading,
  paragraph,
  buttonLabel,
  buttonLink,
  theme,
  secondaryTheme,
  compressed,
  renderChildren,
}) => {
  const style = {
    bottom: true,
    [theme]: true,
    compressed: compressed === true,
  };
  if (secondaryTheme) {
    style[
      `secondary${
        secondaryTheme.charAt(0).toUpperCase() + secondaryTheme.substr(1)
      }`
    ] = true;
  }
  return (
    <Section className={cx(style)}>
      <AnimatedBottom style={{ position: `relative`, zIndex: 2 }}>
        <Container className={cx({ bottomContainer: true, [theme]: true })}>
          <figure />
          {renderChildren ? (
            renderChildren
          ) : (
            <Columns multiline mobile>
              {paragraph && (
                <Columns.Column mobile={{ size: 11 }} tablet={{ size: 11 }}>
                  <HeroChild>{renderText(heading, "h4")}</HeroChild>
                  <HeroChild>
                    {renderText(largeHeading, "h2", styles.largeHeading)}
                  </HeroChild>
                  <HeroChild>
                    {renderHtml(paragraph, "article", styles.bottomContent)}
                  </HeroChild>
                  {buttonLabel && buttonLink && (
                    <Columns multiline mobile>
                      <Columns.Column
                        mobile={{ size: 12 }}
                        tablet={{ size: 6, offset: 6 }}
                      >
                        <HeroChild delayAmount={250}>
                          <PrismicLink
                            link={buttonLink}
                            label={buttonLabel}
                            className={styles.bottomButton}
                          />
                        </HeroChild>
                      </Columns.Column>
                    </Columns>
                  )}
                </Columns.Column>
              )}
            </Columns>
          )}
        </Container>
      </AnimatedBottom>
    </Section>
  );
};

export default class HeroMain extends PureComponent {
  state = {
    showTriangles: false,
    showIndicator:
      BreakpointListener.size === "mobile" ||
      BreakpointListener.size === "tablet",
    headingPose: "headingExit",
  };

  componentDidMount() {
    this.headingTimeout = setTimeout(() => {
      this.setState({ showTriangles: true, headingPose: "headingEnter" });
    }, 750);

    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
  }

  componentWillUnmount() {
    clearTimeout(this.headingTimeout);
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({
      showIndicator: newSize === "mobile" || newSize === "tablet",
    });
  };

  renderBackLink(backLink) {
    if (backLink) {
      let link = backLink.document ? backLink.document[0] : backLink;
      return (
        <Container className={styles.backLink}>
          <HeroChild>
            <Link
              to={`/${link.slug}`}
              title={`Back to ${link.data.title.text}`}
            >
              {link.data.title.text}
            </Link>
          </HeroChild>
        </Container>
      );
    }
    return null;
  }

  render() {
    const {
      id,
      primary: {
        image,
        category,
        show_side_panel,
        theme,
        heading,
        hero_paragraph,
        button_label,
        button_link,
        bottom_callout_heading,
        bottom_callout_large_heading,
        bottom_callout_paragraph,
        bottom_callout_button_label,
        bottom_callout_button_link,
        enable_footer,
        footer_heading,
        show_footer_item_borders,
      },
      items,
      bottomTheme,
      bottomCompressed,
      bottomRenderChildren = null,
      backLink,
    } = this.props;

    const hasBottom = show_side_panel === "true";
    const hasFooter = enable_footer === "true";
    const hasImage = image && image.url ? true : false;
    const hasQuickInfoPanel = false; //enable_floating_side_panel === 'true';

    // console.log({button_link, bottom_callout_button_link});
    //
    return (
      <React.Fragment>
        <Hero
          key={id}
          size={
            !hasImage
              ? "medium"
              : (hasBottom && hasFooter && !bottomRenderChildren) || hasFooter
              ? "large"
              : "medium"
          }
          className={cx({
            hero: true,
            [theme]: true,
            hasImage: hasImage === true,
            hasBottom: hasBottom === true && !bottomRenderChildren,
            hasFooter: hasFooter === true,
            hasCustomBottom: bottomRenderChildren != null,
          })}
        >
          {hasImage && (
            <figure
              className={`image 16by9 ${cx({
                bg: true,
                hasBottom: hasBottom === true,
                hasFooter: hasFooter === true,
                hasCustomBottom: bottomRenderChildren != null,
              })}`}
            >
              {renderFluidImage(image, null, {
                loafing: `eager`,
                backgroundColor: `#171717`,
                maxWidth: [360, 500, 768, 1024, 1368],
              })}
            </figure>
          )}
          <AnimatedBody>
            {this.renderBackLink(backLink)}
            <Hero.Body
              className={cx({
                heroBody: true,
                hasImage: hasImage === true,
                hasFooter: hasFooter === true,
                hasBottom: hasBottom === true && !bottomRenderChildren,
                hasCustomBottom:
                  bottomRenderChildren && bottomRenderChildren !== undefined,
              })}
            >
              <Container>
                <Columns multiline mobile>
                  <Columns.Column
                    mobile={{ size: 10 }}
                    tablet={{ size: 8 }}
                    desktop={{ size: 7 }}
                  >
                    {category && category.text && category.text.length > 0 && (
                      <HeroH5>{category.text}</HeroH5>
                    )}
                    <HeadingContainer
                      pose={this.state.headingPose}
                      withParent={false}
                    >
                      {renderText(
                        heading,
                        `h1`,
                        null,
                        null,
                        null,
                        null,
                        null,
                        true,
                        headingAnimationConfig
                      )}
                    </HeadingContainer>
                    {hero_paragraph && hero_paragraph.html && (
                      <HeroChild
                        className={styles.heroParagraph}
                        dangerouslySetInnerHTML={{
                          __html: hero_paragraph.html,
                        }}
                      />
                    )}
                    {button_link && (
                      <HeroChild>
                        <PrismicLink
                          label={button_label}
                          link={button_link}
                          className={styles.button}
                        />
                      </HeroChild>
                    )}
                  </Columns.Column>
                </Columns>
              </Container>
              {!hasImage && (
                <div className={styles.trianglesContainer}>
                  <Triangles
                    key={`${id}-triangles`}
                    revealed={this.state.showTriangles}
                    autoReveal={false}
                    revealDelay={2500}
                    percentageChanceofNoTriangle={85}
                    masks={{
                      bl: [{ rows: 10, cols: 15 }],
                      tr: [{ rows: 2, cols: 10 }],
                    }}
                    animationType={`tlbr`}
                    theme={theme === "red" ? "black" : "red"}
                    debug={false}
                  />
                </div>
              )}
              {hasFooter && (
                <HeroFooter
                  heading={footer_heading}
                  items={items}
                  theme={theme}
                  showIndicator={this.state.showIndicator}
                  showBorders={show_footer_item_borders === "true"}
                />
              )}
            </Hero.Body>
          </AnimatedBody>
        </Hero>
        {(hasBottom || bottomRenderChildren) && (
          <HeroBottomCallout
            paragraph={bottom_callout_paragraph}
            heading={bottom_callout_heading}
            largeHeading={bottom_callout_large_heading}
            buttonLabel={bottom_callout_button_label}
            buttonLink={bottom_callout_button_link}
            footerEnabled={enable_footer}
            theme={theme}
            secondaryTheme={bottomTheme}
            compressed={bottomCompressed === true}
            renderChildren={bottomRenderChildren}
          />
        )}
        {hasQuickInfoPanel && <QuickInfoPanel items={items} />}
        <SkipNavContent />
      </React.Fragment>
    );
  }
}
