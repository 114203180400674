import React from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';

import { renderFluidImage } from 'utils/imageHelpers';
import classNames from 'classnames/bind';
import styles from './FullWidthCallouts.module.scss';
import { renderText, PrismicLink } from 'utils/renderHelpers';

const cx = classNames.bind(styles);

export default ({ primary, items }) => {
  const theme = primary && primary.theme ? primary.theme : `white`;
  return (
    <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
      <Container>
        <Columns mobile multiline>
          {items.map((item, idx) => {
            const { link, image, category_heading, heading, label } = item;
            const lbl = label && label.text ? label.text : 'Learn more';
            if (!link) {
              return null;
            }
            return (
              <Columns.Column
                key={`fwcallout-${idx}`}
                mobile={{ size: 12 }}
                tablet={{ size: 12 }}
                className={styles.hasLink}
              >
                <PrismicLink link={link} label={lbl} className={styles.link}>
                  <Columns mobile>
                    <Columns.Column
                      mobile={{ size: 3 }}
                      tablet={{ size: 2 }}
                      className={`is-flex ${cx({
                        hasImage: true,
                        hasLinkContent: true
                      })}`}
                    >
                      <figure>
                        {renderFluidImage(image, null, { width: 105 })}
                      </figure>
                    </Columns.Column>
                    <Columns.Column
                      mobile={{ size: 8 }}
                      tablet={{ size: 6 }}
                      className={`is-flex ${cx({
                        hasLinkContent: true,
                        vertCenter: true
                      })}`}
                    >
                      {renderText(category_heading, 'h3')}
                      {renderText(heading, 'h2')}
                    </Columns.Column>
                    <Columns.Column
                      mobile={{ size: 1, hidden: true }}
                      tablet={{ size: 4 }}
                      className={`is-flex is-hidden-mobile ${cx({
                        hasLinkContent: true
                      })}`}
                    >
                      <span className={styles.moreLink}>{lbl}</span>
                    </Columns.Column>
                  </Columns>
                </PrismicLink>
              </Columns.Column>
            );
          })}
        </Columns>
      </Container>
    </Section>
  );
};
